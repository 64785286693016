import { MrbBaseViewStore } from "mrb/core";
import { observable, makeObservable, action, computed } from "mobx";
import { NakiNigunAlbumBulkEditViewStore, NakiNigunArtistBulkEditViewStore } from "application/naki-nigun/stores";
import { nakiNigunContentType } from "application/naki-nigun/models";

class NakiNigunManageSelectedItemsViewStore extends MrbBaseViewStore {
    @observable isBulk = false;

    @computed get isSelectedItemsAny() {
        return this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists
            ? this.artistBulkEditViewStore.isSelectedItemsAny
            : this.albumBulkEditViewStore.isSelectedItemsAny;
    }

    constructor(rootStore, nakiNigunHomeViewStore) {
        super(rootStore);
        makeObservable(this);
        this.nakiNigunHomeViewStore = nakiNigunHomeViewStore;
        this.routeStore = nakiNigunHomeViewStore.routeStore;

        this.albumBulkEditViewStore = new NakiNigunAlbumBulkEditViewStore(this.rootStore, this.nakiNigunHomeViewStore);
        this.artistBulkEditViewStore = new NakiNigunArtistBulkEditViewStore(
            this.rootStore,
            this.nakiNigunHomeViewStore
        );
    }

    @action.bound
    changeIsBulk() {
        if (this.isUpdating) {
            return;
        }
        this.setIsBulk(!this.isBulk);
    }

    @action.bound
    setIsBulk(value) {
        this.isBulk = value;
        if (!this.isBulk) {
            this.clearSelection();
        }
    }

    @action.bound
    selectAll() {
        if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
            this.artistBulkEditViewStore.selectAll();
        } else {
            this.albumBulkEditViewStore.selectAll();
        }
    }

    @action.bound
    deselectAll() {
        if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
            this.artistBulkEditViewStore.deselectAll();
        } else {
            this.albumBulkEditViewStore.deselectAll();
        }
    }

    @action.bound
    selectItem(item) {
        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });
        if (contentAccessResult.canAccess) {
            if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
                this.artistBulkEditViewStore.selectItem(item);
            } else {
                this.albumBulkEditViewStore.selectItem(item);
            }
        }
    }

    @action.bound
    deselectItem(item) {
        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });
        if (contentAccessResult.canAccess) {
            if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
                this.artistBulkEditViewStore.deselectItem(item);
            } else {
                this.albumBulkEditViewStore.deselectItem(item);
            }
        }
    }

    @action.bound
    async changeTurnOn(value) {
        let success;
        if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
            success = await this.artistBulkEditViewStore.changeTurnOn(value);
        } else {
            success = await this.albumBulkEditViewStore.changeTurnOn(value);
        }
        if (success) {
            this.setIsBulk(false);
        }
    }

    @action.bound
    async saveSelection() {
        let success;
        if (this.nakiNigunHomeViewStore.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
            success = await this.artistBulkEditViewStore.saveSelection();
        } else {
            success = await this.albumBulkEditViewStore.saveSelection();
        }
        if (success) {
            this.setIsBulk(false);
        }
    }

    @action.bound
    clearSelection() {
        this.artistBulkEditViewStore.clearSelection();
        this.albumBulkEditViewStore.clearSelection();
    }
}

export default NakiNigunManageSelectedItemsViewStore;
