import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { MrbModalParams } from "mrb/common/models";
import { MrbSimpleSelectStore, MrbQuerySimpleSelectStore } from "mrb/components/select";
import {
    NakiNigunGenericSearchViewStore,
    NakiNigunManageSelectedItemsViewStore,
    NakiNigunHomeFeaturedContentViewStore,
} from "application/naki-nigun/stores";
import { NakiNigunArtist, NakiNigunAlbum, nakiNigunContentType } from "application/naki-nigun/models";
import { UpdateNakiNigunAlbumStatusOptions, UpdateNakiNigunArtistStatusOptions } from "application/common/models";
import { NakiNigunHomeContentFilter } from "application/naki-nigun/filters";
import { calculateNumberOfColumns, calculateNumberOfRows } from "common/utils";
import { isNil, forEach, findIndex, find } from "lodash";

class NakiNigunHomeViewStore extends MrbBaseListViewStore {
    @computed get selectedDevice() {
        return this.rootStore.userDeviceStore.selectedDevice;
    }

    @computed get inSearchMode() {
        return this.nakiNigunGenericSearchViewStore.showSearchView;
    }

    @computed get anyDevice() {
        return this.rootStore.userDeviceStore.devices.length > 0;
    }

    @computed get nakiNigunContentTypeMode() {
        return this.queryUtility.filter.contentType;
    }

    contentTypeOptions = [
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.FILTER.ARTISTS",
            value: nakiNigunContentType.artists,
        },
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.FILTER.ALBUMS",
            value: nakiNigunContentType.albums,
        },
    ];

    artistOrderOptions = [
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.ORDER.ALPHABETICALY_OPTION",
            value: "name",
        },
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.ORDER.RECENTLY_ADDED_OPTION",
            value: "dateCreated",
        },
    ];

    albumOrderOptions = [
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.ORDER.ALPHABETICALY_OPTION",
            value: "title",
        },
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.ORDER.RECENTLY_ADDED_OPTION",
            value: "dateCreated",
        },
    ];

    @computed get orderOptions() {
        if (this.nakiNigunContentTypeMode === nakiNigunContentType.artists) {
            return this.artistOrderOptions;
        }
        return this.albumOrderOptions;
    }

    constructor(rootStore, { routeStore }) {
        const pageSize = calculateNumberOfColumns(window.innerWidth) * calculateNumberOfRows(window.innerWidth);
        super(rootStore, {
            queryConfig: {
                filter: new NakiNigunHomeContentFilter(),
                orderBy: "name",
                orderDirection: "asc",
                pageSize: pageSize,
                queryParamMap: {
                    page: {
                        toQuery: () => 1,
                    },
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.nakiNigunGenericSearchViewStore = new NakiNigunGenericSearchViewStore(this.rootStore, this);
        this.nakiNigunManageSelectedItemsViewStore = new NakiNigunManageSelectedItemsViewStore(this.rootStore, this);
        this.nakiNigunHomeFeaturedContentViewStore = new NakiNigunHomeFeaturedContentViewStore(this.rootStore, this);
        this.connectDeviceModal = new MrbModalParams(
            {
                onClose: () => this.rootStore.userStore.disableConnectDeviceMessage(),
            },
            {
                goToConnectDevice: this.goToConnectDevice,
            }
        );

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
                generateKey: (item) => item.albumId || item.artistId,
                mapper: (item) => {
                    if (item.albumId) {
                        return new NakiNigunAlbum({
                            ...item,
                            coverImageUrl: this.createAlbumCoverImageUrl(item),
                        });
                    } else {
                        return new NakiNigunArtist({
                            ...item,
                            coverImageUrl: this.createArtistCoverImageUrl(item),
                        });
                    }
                },
            })
        );

        this.orderBySelectStore = new MrbQuerySimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                actions: {
                    onChange: (selectedOrder) => {
                        if (selectedOrder.value === "title" || selectedOrder.value === "name") {
                            this.queryUtility.filter.orderDirection = "asc";
                        } else {
                            this.queryUtility.filter.orderDirection = "desc";
                        }
                    },
                },
            },
            this.queryUtility,
            "orderBy",
            this.orderOptions
        );

        this.deviceSelectStore = new MrbSimpleSelectStore(
            {
                textKey: "deviceName",
                valueKey: "id",
                actions: {
                    onChange: (option) => {
                        this.rootStore.userDeviceStore.setSelectedDevice(option.item);
                    },
                },
            },
            this.rootStore.userDeviceStore.devices
        );
        this.deviceSelectStore.setSelectedItem(this.selectedDevice);

        this.reaction(
            () => this.selectedDevice,
            () => {
                if (!this.inSearchMode) {
                    this.queryUtility.fetch();
                }
            }
        );

        this.reaction(
            () => this.inSearchMode,
            (inSearchMode) => {
                if (!inSearchMode) {
                    this.queryUtility.fetch();
                }
            }
        );
    }

    onInit() {
        if (!this.anyDevice && this.rootStore.userStore.isConnectDeviceMessageVisible) {
            this.connectDeviceModal.open();
        }
        return Promise.all([this.nakiNigunHomeFeaturedContentViewStore.onInit(), this.queryUtility.initialize()]);
    }

    findArtists(params) {
        if (this.selectedDevice) {
            params.deviceId = this.selectedDevice.id;
        }
        return this.routeStore.findArtists({
            ...params,
            orderBy: params.orderBy === "title" ? "name" : params.orderBy,
        });
    }

    findAlbums(params) {
        if (this.selectedDevice) {
            params.deviceId = this.selectedDevice.id;
        }
        return this.routeStore.findAlbums({
            ...params,
            orderBy: params.orderBy === "name" ? "title" : params.orderBy,
        });
    }

    async fetchResources(filter) {
        // Display grid (Skeleton loader) only during the first page load, and in other cases, during loading more data on scroll event,
        // display only top bar loader (mainLoaderStore)
        const isFirstPageLoad = filter.pageNumber === 1;
        if (isFirstPageLoad) {
            this.gridStore.suspend();
        } else {
            this.rootStore.mainLoaderStore.suspend();
        }
        try {
            let response;
            if (filter.contentType === nakiNigunContentType.artists) {
                response = await this.findArtists(filter);
            } else {
                response = await this.findAlbums(filter);
            }
            runInAction(() => {
                this.gridStore.setData(response);
                if (isFirstPageLoad) {
                    this.gridStore.resume();
                } else {
                    this.rootStore.mainLoaderStore.resume();
                }
            });
        } catch (err) {
            if (isFirstPageLoad) {
                this.gridStore.resume();
            } else {
                this.rootStore.mainLoaderStore.resume();
            }
            this.onFetchError(err);
        }
    }

    @action.bound
    onAlbumBulkEditSuccess(albumsBulkEdit) {
        forEach(this.gridStore.data, (album) => {
            const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
                isPremium: album.isPremium,
                unavailableInMobileApp: album.isVisibleInApp === false,
            });
            if (contentAccessResult.canAccess) {
                const selectedIndex = findIndex(
                    albumsBulkEdit.selectedIds,
                    (selectedId) => selectedId === album.albumId
                );
                if (selectedIndex !== -1) {
                    album.isTurnedOn = true;
                    return;
                }

                const deselectedIndex = findIndex(
                    albumsBulkEdit.deselectedIds,
                    (deselectedId) => deselectedId === album.albumId
                );
                if (deselectedIndex !== -1) {
                    album.isTurnedOn = false;
                    return;
                }

                if (!isNil(albumsBulkEdit.isAllSelected)) {
                    album.isTurnedOn = albumsBulkEdit.isAllSelected;
                }
            }
        });
        this.nakiNigunHomeFeaturedContentViewStore.onFeaturedAlbumBulkEditSuccess(albumsBulkEdit);
    }

    @action.bound
    onArtistBulkEditSuccess(artistsBulkEdit) {
        forEach(this.gridStore.data, (artist) => {
            const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
                isPremium: artist.isPremium,
                unavailableInMobileApp: artist.isVisibleInApp === false,
            });
            if (contentAccessResult.canAccess) {
                const selectedIndex = findIndex(
                    artistsBulkEdit.selectedIds,
                    (selectedId) => selectedId === artist.artistId
                );
                if (selectedIndex !== -1) {
                    artist.isTurnedOn = true;
                    return;
                }
                const deselectedIndex = findIndex(
                    artistsBulkEdit.deselectedIds,
                    (deselectedId) => deselectedId === artist.artistId
                );
                if (deselectedIndex !== -1) {
                    artist.isTurnedOn = false;
                    return;
                }
                if (!isNil(artistsBulkEdit.isAllSelected)) {
                    artist.isTurnedOn = artistsBulkEdit.isAllSelected;
                }
            }
        });
        this.nakiNigunHomeFeaturedContentViewStore.onFeaturedArtistBulkEditSuccess(artistsBulkEdit);
    }

    async toggleAlbumTurnedOn(item, deviceId) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateNakiNigunAlbumStatusOptions(
                    item.albumId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false
                );
                await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatus(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    this.nakiNigunHomeFeaturedContentViewStore.onAlbumTurnedOnStatusChange(item);
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    async toggleArtistTurnedOn(item, deviceId) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateNakiNigunArtistStatusOptions(
                    item.artistId,
                    item.name,
                    item.isPremium,
                    item.isVisibleInApp === false
                );
                await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatusByArtist(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    this.nakiNigunHomeFeaturedContentViewStore.onArtistTurnedOnStatusChange(item);
                });
            } catch (err) {
                console.log(err);
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    @action.bound
    changeContentTypeFilter(value) {
        this.queryUtility.filter.set("contentType", value);
        this.queryUtility.fetch();
    }

    goTo = (item) => {
        this.rootStore.routerStore.goTo("master.application.naki-nigun.artist", {
            id: item.artistId,
        });
    };

    goToConnectDevice = () => {
        this.rootStore.routerStore.goTo("master.application.device.create");
    };

    onClickCellItem = async (item) => {
        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });
        if (contentAccessResult.canAccess) {
            if (item instanceof NakiNigunArtist) {
                await this.toggleArtistTurnedOn(item, this.selectedDevice.id);
            } else if (item instanceof NakiNigunAlbum) {
                await this.toggleAlbumTurnedOn(item, this.selectedDevice.id);
            }
        }
    };

    @action.bound
    onFeaturedAlbumTurnedOnStatusChange(changedAlbum) {
        const album = find(this.gridStore.data, (item) => item.albumId === changedAlbum.albumId);
        if (album && album.isTurnedOn !== changedAlbum.isTurnedOn) {
            album.isTurnedOn = changedAlbum.isTurnedOn;
        }
    }

    @action.bound
    onFeaturedArtistTurnedOnStatusChange(changedArtist) {
        const artist = find(this.gridStore.data, (item) => item.artistId === changedArtist.artistId);
        if (artist && artist.isTurnedOn !== changedArtist.isTurnedOn) {
            artist.isTurnedOn = changedArtist.isTurnedOn;
        }
    }

    onFetchError(error) {
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    createArtistCoverImageUrl(item) {
        if (item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(item.coverImageId, 200, 200);
        }
        return null;
    }

    createAlbumCoverImageUrl(item) {
        if (item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(item.coverImageId, 200, 200);
        }
        return null;
    }

    onDestroy() {
        this.nakiNigunGenericSearchViewStore.destroy();
        this.nakiNigunManageSelectedItemsViewStore.destroy();
        this.nakiNigunHomeFeaturedContentViewStore.destroy();
    }
}

export default NakiNigunHomeViewStore;
